/*------------- #W-LIST --------------*/



.w-list {
  .list {
	margin-left: 0;
	li {
	  font-weight: 400;
	  margin-bottom: .2em;

	  & > * {
		display: inline-block;
		vertical-align: middle;
	  }

	  &:hover {
		.utouch-icon {
		  opacity: 1;
		}

		a {
		  color: $primary-color;
		  margin-left: 8px;
		}
	  }

	  .utouch-icon {
		width: 16px;
		height: 16px;
		opacity: 0;
		margin-right: 0;
		margin-top: 2px;
		float: none;
	  }
	}
  }

  .list li a {
	color: $body-font-color;
	margin-right: 8px;
  }

}