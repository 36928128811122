/*------------- #W-CONTACTS --------------*/



.w-contacts {

  .btn {
	margin: 20px 0;
  }

  .socials {
	margin-top: 25px;
  }

  #map {
	margin: 0 -40px 35px;
  }

  .info-wrap {
	.info {
	  display: block;
	}
  }

  a.info:hover {
	color: $primary-color;
  }
}

.contact-item {
  margin-bottom: 5px;

  .utouch-icon {
	height: 18px;
	width: 18px;
	color: $primary-color;
	fill: $primary-color;
	margin-right: 10px;
  }
}

.contacts-text {
  font-size: 18px;
  font-weight: $h-font-weight;
  margin-bottom: 35px;
}

.w-contacts--style2 {
  .contact-item {
	margin-bottom: 15px;

	.utouch-icon {
	  height: 44px;
	  min-width: 44px;
	  margin-right: 30px;
	}
  }

  .info {
	font-weight: $h-font-weight;
	color: $heading-color;

	span {
	  font-weight: 300;
	}
  }
}

#map {
  min-width: 100%;
  height: 220px;
  max-height: 90vh;
  margin-bottom: 40px;
}

.sidebar {
  .widget {
	padding: 20px 40px 40px;
	border-radius: 10px;
	box-shadow: 10px 0 50px rgba(18,25,33,.1);
	margin-bottom: 40px;
  }
}

.map--rounded {
  border-radius: 10px;
  box-shadow: 30px 0 100px rgba(0,0,0,.2);
  position: relative;

  #map {
	height: 360px;
	margin-bottom: 60px;
  }
}

.location-details {
  width: 260px;
  padding: 40px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 10px 0 50px rgba(18, 25, 33, 0.1);
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(0, -50%);
  max-width: 100%;

  .contacts-text {
	font-size: 16px;
	font-weight: $h-font-weight;
	color: $heading-color;
	margin-bottom: 20px;
  }

  a:hover {
	color: $primary-color;
  }
}



/*================= Responsive Mode ============*/


@media (max-width: 580px) {
  .location-details {
	right: 0;
	padding: 15px;
  }

  .sidebar .widget {
	padding: 20px;
  }

  .w-contacts #map {
	margin: 0 -20px 40px;
  }
}

@media (max-width: 420px) {
  .location-details {
	display: none;
  }
}