/*------------- #WIDGETS --------------*/

@import "theme-styles/variables";
@import "widgets/w-info";
@import "widgets/w-list";
@import "widgets/w-follow";
@import "widgets/w-contacts";
@import "widgets/w-search";
@import "widgets/w-about";
@import "widgets/w-category";
@import "widgets/w-tags";
@import "widgets/w-popular-products";
@import "widgets/w-latest-news";
@import "widgets/w-events";
@import "widgets/w-latest-members";
@import "widgets/w-author";


