/*------------- #W-EVENTS --------------*/


.event-day {
  margin: 0 auto 40px;
  border-radius: 30px;
  background-color: $blue-lighteen;
  padding: 11px 14px;
  line-height: 1;
  font-size: 14px;
  font-weight: $h-font-weight;
  text-transform: uppercase;
  text-align: center;
  color: $icon-color;
  display: table;
}

.w-events {
  .event-item {
	padding-bottom: 25px;
	margin-bottom: 25px;
	border-bottom: 1px solid $border-color;

	&:last-child {
	  border-bottom: none;
	}
  }
}
